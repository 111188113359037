import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { addListener, isAnyOf, isFulfilled } from '@reduxjs/toolkit'
import {
  saveAll as saveAllAction,
  togglePreview as togglePreviewAction,
  setInitialState as initStateAction,
  selectPackageTemplatesForm,
  attachDocument,
  attachForm,
  selectMyDocumentsModalState,
} from '../store/slice'
import useHeaderRow from './HeaderRow'
import Requirements from './requirements/PackageRequirements'
import axios from 'lib/axios'
import PackagePreviewModal from './PackagePreviewModal'
import PackageTemplateYamlUpload from './PackageTemplateYamlUpload'
import Flash from './flashses'

const useUpdateFormDocuments = (getValues, setValue) => {
  const dispatch = useDispatch()
  const { visible, requirement, buildingId } = useSelector(selectMyDocumentsModalState)

  const updateRequirementTemplateDocuments = useCallback(
    (doc) => {
      const package_requirements = getValues('package_template.package_requirements')
      const requirementIndex = package_requirements.findIndex(
        (package_requirement) => package_requirement?.id === requirement.id
      )
      if (requirementIndex === -1) return

      const key = `package_template.package_requirements.${requirementIndex}.package_template_documents`
      const package_template_documents = getValues(key)
      setValue(key, [...package_template_documents, doc])
    },
    [requirement, setValue, getValues]
  )

  useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        actionCreator: attachDocument.fulfilled,
        effect: (action, listenerApi) => {
          const { document: doc, status } = action.payload
          if (!doc || status !== 'Success') return

          updateRequirementTemplateDocuments(doc)
        },
      })
    )

    return unsubscribe
  }, [dispatch, updateRequirementTemplateDocuments])

  useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        actionCreator: attachForm.fulfilled,
        effect: (action, listenerApi) => {
          const { document: doc, status } = action.payload
          if (!doc || status !== 'Success') return

          updateRequirementTemplateDocuments(doc)
        },
      })
    )

    return unsubscribe
  }, [dispatch, updateRequirementTemplateDocuments])
}

function Main() {
  const dispatch = useDispatch()

  const {
    building,
    package_template,
    package_type_label,
    package_types,
    package_template_states,
    package_template_active_state,
    is_super_admin,
    has_rofr_waiver,
    can_upload_template_yaml,
  } = useSelector(selectPackageTemplatesForm)

  const package_template_states_keys = Object.keys(package_template_states)

  const defaultValues = {
    active_state: package_template_active_state || package_template_states[package_template_states_keys[0]],
    package_template: package_template,
    rofr_waiver: package_template.rofr_waiver_id,
  }

  const methods = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onBlur',
  })

  const {
    register,
    reset,
    formState: { isValid, errors, isDirty },
    getValues,
    setValue,
    trigger,
    watch,
    control,
  } = methods

  useUpdateFormDocuments(getValues, setValue)

  const recognition_agreement_enabled = watch('package_template.recognition_agreement_enabled')

  const needsSave = useMemo(() => {
    return isDirty
  }, [isDirty])

  const onSaveAll = async () => {
    await trigger()

    if (isValid) {
      dispatch(saveAllAction({ formData: getValues(), package_template_id: package_template.id }))
    } else {
      // TODO Display errors
      console.log(isValid, errors)
    }
  }

  const [previewModalVisible, setPreviewModalVisible] = useState(false)

  const onPreview = useCallback(() => setPreviewModalVisible(true), [setPreviewModalVisible])
  const onPreviewClose = useCallback(() => setPreviewModalVisible(false), [setPreviewModalVisible])
  const HeaderRow = useHeaderRow({
    editing: !!package_template.id,
    building,
    packageTypeLabel: package_type_label,
    label: package_template.label,
    isSuper: false,
    onSaveAll,
    onPreview,
  })

  const renderTemplateFields = () => {
    return (
      <div className="package-template-header-container row">
        <div className="row form-group" style={{ paddingLeft: 1 }}>
          <label className="col-xs-2 col-form-label" style={{ fontSize: '1.45rem', fontWeight: 400 }}>
            Template Title
          </label>
          <div className="col-xs-10">
            <input
              type="text"
              style={{ width: '35%' }}
              {...register('package_template.label', {
                required: true,
              })}
            />
          </div>
        </div>

        <div className="row form-group">
          <label className="col-xs-2 col-form-label" style={{ fontSize: '1.45rem', fontWeight: 400 }}>
            {package_type_label} Type
          </label>
          <div className="col-xs-10">
            <select
              className="edit-package-template-select"
              {...register('package_template.package_type', { required: true })}
            >
              <option disabled value="">
                Select Package Type
              </option>
              {package_types.map((package_type) => (
                <option key={package_type.package_type} value={package_type.package_type}>
                  {package_type.adapted_title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row form-group">
          <label
            className="col-form-label col-xs-2"
            style={{ fontSize: '1.45rem', fontWeight: 400 }}
            htmlFor="active-state"
          >
            Visibility State
          </label>
          <div className="col-xs-3">
            <select
              name="active_state"
              className="edit-package-template-select"
              {...register('active_state', { required: true })}
            >
              {package_template_states_keys.map((key, index) => (
                <option key={key} value={package_template_states[key]}>
                  {key}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    )
  }

  const renderSuperAdminOptions = () => {
    if (!is_super_admin) return null

    if (package_template['is_alteration?'])
      return (
        <div className="row form-group">
          <label className="col-xs-2 col-form-label" style={{ fontSize: '1.45rem', fontWeight: 400 }}>
            Enable Property Architect Report
          </label>
          <div className="col-xs-3">
            <input
              type="checkbox"
              name="global-property-architect-report"
              id="global-property-architect-report"
              className="edit-package-template-select form-control"
              {...register('package_template.property_architect_report_enabled')}
            />
          </div>
        </div>
      )

    return (
      <>
        {has_rofr_waiver && (
          <div className="row form-group">
            <label className="col-xs-2 col-form-label" style={{ fontSize: '1.45rem', fontWeight: 400 }}>
              ROFR Waiver Form ID
            </label>
            <div className="col-xs-3">
              <input
                id="rofr-waiver-id"
                className="edit-package-template-select form-control"
                type="text"
                {...register('rofr_waiver')}
              />
            </div>
          </div>
        )}
        <div className="row form-group">
          <label className="col-xs-2 col-form-label" style={{ fontSize: '1.45rem', fontWeight: 400 }}>
            Enable Recognition Agreement
          </label>
          <div className="col-xs-3">
            <input
              type="checkbox"
              id="global-recognition-agreement"
              className="edit-package-template-select form-control"
              {...register('package_template.recognition_agreement_enabled')}
            />
          </div>
        </div>
      </>
    )
  }

  const onRemoveRequirementRemotely = async (requirementId) => {
    const url = '/package_templates/' + package_template.hashed_id + '/requirements/' + requirementId
    return await axios.delete(url)
  }

  const onRemoveDocumentRemotely = async (requirementId, templateDocumentId) => {
    const url =
      '/package_templates/' +
      package_template.hashed_id +
      '/requirements/' +
      requirementId +
      '/documents/' +
      templateDocumentId

    return await axios.delete(url)
  }

  const onAddToLibrary = async (package_hashed_id) => {
    try {
      const url = '/packages/' + package_hashed_id + '/compile_or_ok_download'
      const data = { building_id: building.id }
      const response = await axios.post(url, data, { headers: { Accept: 'application/json' } })
      swal({
        title: 'Adding to your Library!',
        text: "Please note this application is a large file and might take some time to show up in your library. We'll send you an email notification when it's ready!",
        type: 'success',
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <HeaderRow />
      {needsSave && <Flash onSaveAll={onSaveAll} />}
      {can_upload_template_yaml && <PackageTemplateYamlUpload package_hashed_id={package_template.hashed_id} />}
      <FormProvider {...methods}>
        <form>
          {renderTemplateFields()}
          {renderSuperAdminOptions()}
          <Requirements
            recognition_agreement_enabled={recognition_agreement_enabled}
            onRemoveRequirementRemotely={onRemoveRequirementRemotely}
            onRemoveDocument={onRemoveDocumentRemotely}
          />
        </form>
      </FormProvider>
      <PackagePreviewModal
        open={previewModalVisible}
        onClose={onPreviewClose}
        package_hashed_id={package_template.hashed_id}
        onAddToLibrary={onAddToLibrary}
      />
    </>
  )
}

export default Main
